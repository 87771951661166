import { LaunchRounded } from '@mui/icons-material'
import type { SxProps } from '@mui/material'
import {
  Box,
  Container,
  Grid2,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import type { ButtonExProps } from '@xylabs/react-button'
import type { FlexBoxProps } from '@xylabs/react-flexbox'
import { FlexGrowCol } from '@xylabs/react-flexbox'
import React from 'react'

import { AppBarOffset } from '../AppBarOffset.tsx'
import { ButtonSection } from './ButtonSection.tsx'

export interface BasicHeroProps extends FlexBoxProps {
  backgroundImage?: string
  button1Props?: ButtonExProps
  button1Text?: string
  button2Props?: ButtonExProps
  button2Text?: string
  desc: string
  extras?: React.ReactNode
  heroImage?: string
  heroImageProps?: React.CSSProperties
  subLinkSection?: React.ReactNode
  sx?: Record<string, string>
  title: string
}

const HeroButtons: React.FC<{
  button1Props?: ButtonExProps
  button1Text?: string
  button2Props?: ButtonExProps
  button2Text?: string
}> = ({
  button1Text,
  button1Props,
  button2Text,
  button2Props,
}) => (
  <Stack
    sx={{ flexDirection: { md: 'row', xs: 'column' } }}
    gap={1}
    marginTop={1}
  >
    <ButtonSection
      endIcon={
        button1Props?.['href'] === undefined ? undefined : <LaunchRounded />
      }
      buttonText={button1Text}
      onClick={() => {
        if (button1Props?.['to']?.toString().startsWith('#')) {
          document.querySelector(button1Props?.['to'].toString())?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }}
      {...button1Props}
    />
    <ButtonSection
      variant="outlined"
      endIcon={
        button2Props?.['href'] === undefined ? undefined : <LaunchRounded />
      }
      buttonText={button2Text}
      onClick={() => {
        if (button2Props?.['to']?.toString().startsWith('#')) {
          document.querySelector(button2Props?.['to'].toString())?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }}
      {...button2Props}
    />
  </Stack>
)

interface HeroContentProps extends FlexBoxProps {
  buttonsProps: React.ComponentProps<typeof HeroButtons>
  desc: string
  extras?: React.ReactNode
  subLinkSection?: React.ReactNode
  sx?: SxProps
  title: string
}
const HeroContent: React.FC<HeroContentProps> = ({
  title, desc, subLinkSection, extras, buttonsProps, sx, ...props
}) => {
  return (
    <FlexGrowCol
      paddingY={3}
      sx={{ ...sx }}
      {...props}
    >
      <Typography
        variant="h1"
        gutterBottom
        sx={{ textAlign: { md: 'left', xs: 'center' }, fontSize: { md: '3.5rem', lg: '4rem' } }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        component="h2"
        gutterBottom
        sx={{ textAlign: { md: 'left', xs: 'center' } }}
      >
        {desc}
      </Typography>
      <HeroButtons {...buttonsProps} />
      {subLinkSection}
      {extras}
    </FlexGrowCol>
  )
}

export const XyoBasicHero: React.FC<BasicHeroProps> = ({
  backgroundImage,
  title,
  desc,
  button1Text,
  button2Text,
  button1Props,
  button2Props,
  extras,
  subLinkSection,
  heroImage,
  heroImageProps,
  sx,
}) => {
  const theme = useTheme()

  return (
    <Box>
      <FlexGrowCol
        position="relative"
        sx={{
          backgroundImage: { md: `url(${backgroundImage})`, xs: 'none' },
          backgroundPosition: 'center left',
          backgroundSize: { md: 'cover', xs: '100%' },
          justifyContent: 'flex-start',
          paddingTop: { md: theme.spacing(0), xs: theme.spacing(2) },
          ...sx,
        }}
        style={{ backgroundRepeat: 'no-repeat' }}
      >
        <AppBarOffset />
        <Container maxWidth="lg" sx={{ overflow: 'visible' }}>
          <Grid2
            container
            sx={{
              alignItems: 'stretch', justifyContent: { xs: 'center', md: 'flex-start' }, position: 'relative',
            }}
          >
            <HeroContent
              title={title}
              desc={desc}
              subLinkSection={subLinkSection}
              extras={extras}
              buttonsProps={{
                button1Text,
                button2Text,
                button1Props,
                button2Props,
              }}
              sx={{
                alignItems: { md: 'flex-start', xs: 'center' },
                marginTop: { md: 3, xs: 2 },
                maxWidth: {
                  xs: '100%', md: '70%', lg: heroImage === undefined ? '70%' : '40%',
                },
              }}
            />
            {heroImage && (
              <FlexGrowCol
                position="absolute"
                right={0}
                bottom={0}
                sx={{
                  backgroundImage: { md: `url(${heroImage})`, xs: 'none' },
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  width: '700px',
                  minHeight: {
                    md: '600px', sm: '500px', xs: '400px',
                  },
                  display: { lg: 'flex', xs: 'none' },
                  ...heroImageProps,
                }}
              />
            )}
          </Grid2>
        </Container>
      </FlexGrowCol>
    </Box>
  )
}
