/* eslint-disable complexity */
import {
  styled, Typography, useTheme,
} from '@mui/material'
import { BasePage } from '@xylabs/react-base-page'
import type { BasePageProps } from '@xylabs/react-common'
import { RedirectWithQuery } from '@xylabs/react-common'
import { CookieConsent } from '@xylabs/react-cookie-consent'
import { FlexCol, FlexRow } from '@xylabs/react-flexbox'
import { InvertibleThemeProvider } from '@xylabs/react-invertible-theme'
import { ScrollToTop } from '@xylabs/react-scroll-to-top'
import { isLocalhost } from '@xylabs/react-shared'
import { forget } from '@xylabs/sdk-js'
import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useSettings } from '../contexts/index.ts'
import { XyoAppBar } from '../Header/index.ts'
import { useXyoUserEvents } from '../hooks/index.ts'
import { BackgroundGradientBox1 } from './GradientBackgrounds.tsx'
import { Newsletter } from './Newsletter.tsx'
import type { XyoFooterStyledProps } from './XyoFooter/index.ts'
import { XyoFooterStyled } from './XyoFooter/index.ts'

export interface XyoBasePageProps extends BasePageProps {
  bottomBanner?: boolean
  devOnly?: string | boolean
  devOnlyTo?: string | boolean
  extraAppBarSpace?: boolean
  footerProps?: XyoFooterStyledProps
  gradientBackground?: boolean
  localAsDev?: boolean
  name?: string
  ogImageMetaName?: string
  shareImage?: string
}

export const XyoBasePage: React.FC<PropsWithChildren<XyoBasePageProps>> = ({
  devOnly = false,
  devOnlyTo = false,
  localAsDev = false,
  children,
  extraAppBarSpace = false,
  gradientBackground = true,
  name,
  title,
  shareImage,
  bottomBanner = true,
  footerProps,
  ...props
}) => {
  const { developerMode } = useSettings()
  const userEvents = useXyoUserEvents()
  const location = useLocation()
  const theme = useTheme()
  const devRequirementPassed = !(devOnly || devOnlyTo) || developerMode || (localAsDev && isLocalhost)
  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar)

  useMemo(() => {
    forget(userEvents.viewContent({ name: name ?? title ?? 'XyoBasePage', path: location.pathname }))
  }, [userEvents, name, title, location.pathname])

  const finalTitle = title ? `XYO | ${title}` : 'XYO'

  return (
    <BasePage
      title={finalTitle}
      appBar={<XyoAppBar />}
      appFooter={(
        <XyoFooterStyled {...footerProps} />
      )}
      cookieConsent={<CookieConsent />}
      metaServer={{ shareImage, title: finalTitle }}
      {...props}
    >
      <BackgroundGradientBox1 sx={{ background: gradientBackground ? null : theme.palette.background.default }}>
        <ScrollToTop />
        {devOnly && devRequirementPassed
          ? (
              <FlexRow margin={1}>
                <Typography variant="body1">
                  Important: This page is a Developer Only page. It is possible that some information may not be correct.
                </Typography>
              </FlexRow>
            )
          : null}
        {devOnly && !devRequirementPassed
          ? <RedirectWithQuery to="/" />
          : null}
        {/* This is here to provide spacing under fixed header */}
        {extraAppBarSpace
          ? <Offset />
          : null}
        {children}
        {bottomBanner
          ? <Newsletter />
          : null}
      </BackgroundGradientBox1>
    </BasePage>
  )
}
